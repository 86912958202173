import styles from './App.module.scss';
import { Input } from './components/input/input';
import { Label } from './components/label/label';
import { SubmitButton } from './components/submit-button/submit-button';
import { RiSendPlaneFill } from 'react-icons/ri';

function App() {
    function handleOnSubmit(e: React.SyntheticEvent<HTMLFormElement>) {
        e.preventDefault();
        //console.log(e);

        //Array.from(e.currentTarget.elements)

        const formData: Record<string, string> = {};

        function isInputNamedElement(e: Element): e is HTMLInputElement & { name: string } {
            return 'value' in e && 'name' in e;
        }

        Array.from(e.currentTarget.elements)
            .filter(isInputNamedElement)
            .forEach((field) => {
                if (!field.name) return;
                formData[field.name] = field.value;
            });
        console.log(formData);
        fetch('/.netlify/functions/mail', {
            method: 'POST',
            body: JSON.stringify(formData),
        })
            .then((response) => {
                // Do something here on success!
                window.alert('send successfully!');
            })
            .catch((error) => {
                // Do something here on error!
            });
    }
    return (
        <div className={styles.App}>
            <h1>Contact Us</h1>
            <p>help us to improve our service</p>
            <form onSubmit={handleOnSubmit} className={styles.form}>
                <Label className="" htmlFor={'name'}>
                    name
                </Label>
                <Input id={'name'} name="name" />
                <Label htmlFor={'email'}>email</Label>
                <Input id={'email'} name="email" />
                <Label htmlFor={'mess'}>message</Label>
                <Input id={'mess'} name={'message'} className={styles.messagebox} />
                <SubmitButton>
                    <RiSendPlaneFill className={styles.plane} />
                    Button
                </SubmitButton>
            </form>
        </div>
    );
}

export default App;
